<template>
  <div>
    <Modal
      :visible.sync="confirm_visible"
      :title="title"
      :content="content"
      :html_content="html_content"
      @confirm="user_click"
      :cancel_btn_required="type === 'confirm'"
    >
    </Modal>
  </div>
</template>

<script>
import Modal from '@/libs/cb-theme/Modal'

export default {
  name: 'Dialog',

  components: {
    Modal
  },

  // 目前只能改title, content，之後需要改其他的再接
  props: {
    // 標題
    title: {
      type: String,
      default: '標題'
    },

    // 內容
    content: {
      type: String,
      default: null
    },
    // html格式的內容
    html_content: {
      type: String,
      default: null
    },
    // P.S. 傳入內容的優先度： html_content > content

    // dialog類型, "confirm" | "alert"
    type: {
      type: String,
      default: 'confirm'
    }
  },

  data() {
    return {
      confirm_visible: false,
      promise_status: null,
    }
  },

  methods: {
    launch() {
      this.confirm_visible = true

      let _this = this
      return new Promise((resolve, reject) => {
        _this.promise_status = {resolve, reject}
      })
    },

    user_click(ok) {
      if (ok) {
        this.promise_status && this.promise_status.resolve()
      }
      else {
        this.promise_status && this.promise_status.reject()
      }
      // TODO: 可能會有會需要重置的問題？之後再來試 by I
    }
  }
}
</script>