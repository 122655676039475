<template>
  <div id="main">
    <main>
      <h2 v-if="user_vstate.employee" class="title">{{ wording(user_vstate.employee.nickname) }} / <span>習慣到班時間&lt;{{ wording(user_vstate.employee.arrival_time) }}&gt;</span></h2>
      <i class="icon-info info_btn tutorial" @click="tutorial_modal_visible = true"></i>
      <span class="annual_info" v-if="get_selected_annual_leave_info() !== undefined">
        {{ wording(selected_year) }}年度休假時數：{{ wording(get_year_leave_hours()) }}
        <span>(基準: {{ wording(get_selected_annual_leave_info().base_hours) }} + 轉移: {{ wording(get_selected_annual_leave_info().unspent_hours) }})</span>
      </span>
      <div class="flex_row">
        <div class="hours_block">
          <div>
            可休時數<span>{{ wording(remaining_hours) }}</span>
          </div>
          <div>
            已休時數<span>{{ wording(taken_hours('休假')) }}</span>
          </div>
          <div>
            已休病假<span>{{ wording(taken_hours('傷病假')) }}</span>
          </div>
        </div>
      </div>
      <div class="flex_row">
        <div class="year_select">
          <label class="flex_box container_select--base">
            <select
              v-model="selected_year"
              class="column_full input--base"
            >
              <option
                v-for="(year, idx) in year_options"
                :key="'year_' + idx"
                :value="year"
              >{{ year }}</option>
            </select>
            <i class="icon-arrow_down"></i>
          </label>
        </div>
        <div class="flex_box">
          <button
            class="btn_primary circle_radius add_btn"
            @click="add_leave"
          >
            <i class="icon-symbol_add"></i>
            新增
          </button>
        </div>
      </div>
      <table>
        <tr>
          <th>假別</th>
          <th>起始</th>
          <th>結束</th>
          <th>時數</th>
          <th></th>
        </tr>
        <tr
          v-if="!leave_vstate.leave_list || !leave_vstate.leave_list.length"
          class="empty_tr"
        >
          <td colspan="5">
            暫無資料
          </td>
        </tr>
        <tr
          v-for="(leave, idx) in leave_vstate.leave_list"
          :key="'leave_' + idx"
          @click="edit_leave(leave)"
        >
          <td>{{ leave.leave_type }}</td>
          <td>{{ $datetime_format(leave.begin_at) }}</td>
          <td>{{ $datetime_format(leave.end_at) }}</td>
          <td>{{ leave.hours }}</td>
          <td>
            <button
              class="btn_primary btn_icon delete_btn circle_radius"
              @click.stop="delete_leave(leave)"
            >
              <i class="icon-symbol_subtract"></i>
            </button>
          </td>
        </tr>
      </table>
    </main>

    <Modal
      v-if="leave_vstate.editing_leave"
      :visible.sync="leave_modal_visible"
      :title="leave_vstate.editing_leave.id === undefined ? '新增紀錄' : '編輯紀錄'"
      @confirm="save_leave"
      :disabled="save_leave_disabled"
      card_style="leave_modal_card"
      cancel_btn_required
      closable
    >
      <template v-slot:content>
        <div class="modal_content_form">
          <div>
            <h4>假別</h4>
            <label class="flex_box container_select--base">
              <select
                v-model="leave_vstate.editing_leave.leave_type"
                class="column_full input--base"
              >
                <option disabled :value="null"></option>
                <option
                  v-for="(leave_type, idx) in leave_types"
                  :key="'leave_type_' + idx"
                  :value="leave_type"
                >{{ leave_type }}</option>
              </select>
              <i class="icon-arrow_down"></i>
            </label>
          </div>
          <div>
            <h4>起始<i class="icon-info info_btn" @click="time_tutorial_modal_visible = true"></i></h4>
            <div class="picker_container">
              <div>
                <datetime
                  v-model="editing_begin_at"
                  :type="begin_date_only ? 'date' : 'datetime'"
                  :minute-step=30
                  :auto=true
                  format="yyyy-MM-dd HH:mm"
                  :max-datetime="editing_end_at"
                >
                </datetime>
                <i class="icon-symbol_close" @click.stop="clear_time('begin_at')"></i>
              </div>
              <label
                for="checkbox--toggle"
                class="container_checkbox--toggle date_only_btn"
              >
                <input
                  type="checkbox"
                  id="checkbox--toggle"
                  v-model="begin_date_only"
                >
                <div class="flex_box btn btn_normal btn_toggle">
                  <span>只選日期</span>
                </div>
              </label>
            </div>
          </div>
          <div>
            <h4>結束<i class="icon-info info_btn" @click="time_tutorial_modal_visible = true"></i></h4>
            <div class="picker_container">
              <div>
                <datetime
                  v-model="editing_end_at"
                  :type="end_date_only ? 'date' : 'datetime'"
                  :minute-step=30
                  :auto=true
                  :hide-backdrop="false"
                  format="yyyy-MM-dd HH:mm"
                  :min-datetime="editing_begin_at"
                >
                </datetime>
                <i class="icon-symbol_close" @click.stop="clear_time('end_at')"></i>
              </div>
              <label
                class="container_checkbox--toggle date_only_btn"
              >
                <input
                  type="checkbox"
                  v-model="end_date_only"
                >
                <div class="flex_box btn btn_normal btn_toggle">
                  <span>只選日期</span>
                </div>
              </label>
            </div>
          </div>
          <div>
            <h4>事由</h4>
            <div class="flex_box container_input--base">
              <textarea
                class="column_full input--base"
                v-model="leave_vstate.editing_leave.reason"
                rows="5"
              >
              </textarea>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :visible.sync="tutorial_modal_visible"
      title="請假系統使用須知"
      card_style="tutorial_modal_card"
      closable
      mask_closable
    >
      <template v-slot:content>
        <div class="modal_content_list">
          <ol>
            <li>如需請假請按本頁右方的「+新增」填寫假單。</li>
            <li>假單的假別、時間、事由，皆為必填欄位，起始和結束需至少填其中一個時間。</li>
            <li>送出假單後，該請假紀錄會出現在下方表格，點選該紀錄可以編輯，右方「-」按鈕可以刪除。</li>
            <li>系統會自動計算扣除假日以及中午午休後的時數，記得確認表格顯示的時數是否正確。</li>
            <li>若請了跨年度的假，系統會自動分成兩筆請假紀錄。</li>
            <li>若有修改或刪除休假，請記得自行上 <a href="https://calendar.google.com/calendar" target="_blank">&lt;雲灣 Calendar&gt;</a> 修改或刪除相關事件</li>
            <li>有關請休假之相關規定，請參考 <a href="https://lwsu.gitlab.io/regulation/#%E8%AB%8B%E5%81%87" target="_blank">&lt;雲灣公司規章&gt;</a></li>
          </ol>
        </div>
      </template>
      <template v-slot:footer>
        <div></div>
      </template>
    </Modal>
    <Modal
      :visible.sync="time_tutorial_modal_visible"
      title="時間填寫方法"
      card_style="tutorial_modal_card"
      closable
      mask_closable
    >
      <template v-slot:content>
        <div class="modal_content_list">
          <ul>
            <li>
              時間後方的「只選日期」，當點選按鈕亮起代表可以只選日期不選時間，系統會自動帶入所選日期的習慣到班/下班時間。例：
              <ol>
                <li>「起始：2021-01-17，結束：2021-01-20」<br>代表請4天假，從2021-01-17習慣到班時間請到2021-01-20習慣下班時間。</li>
                <li>「起始：2021-01-17，結束：2021-01-20 12:30」<br>代表請3.5天假，從2021-01-17習慣到班時間請到2021-01-20 12:30。</li>
                <li>「起始：2021-01-17 12:30，結束：2021-01-20」<br>代表請3.5天假，從2021-01-17 12:30請到2021-01-20習慣下班時間。</li>
              </ol>
            </li>
            <li>
              起始和結束可擇一填寫，代表請一整天或早退（只填起始）或晚到（只填結束），另外一項會自動填入當天的習慣到班/下班時間。例：
              <ol>
                <li>只填「起始：2021-01-19」或「結束：2021-01-19」<br>代表請假一整天。</li>
                <li>只填「起始：2021-01-19 15:00」<br>代表早退，15:00離開辦公室。</li>
                <li>只填「結束：2021-01-19 11:00」<br>代表晚到，11:00到辦公室。</li>
              </ol>
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:footer>
        <div></div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { LeaveVState } from '@/state/leave'
import { EmployeeVState } from '@/state/employee'
import { LEAVE_TYPES } from '@/settings'
import { UserVState } from '@/state/user'

export default {
  name: 'Main',

  data() {
    return {
      leave_vstate: LeaveVState,
      employee_vstate: EmployeeVState,
      user_vstate: UserVState,
      leave_types: LEAVE_TYPES,

      leave_modal_visible: false,
      tutorial_modal_visible: false,
      time_tutorial_modal_visible: false,

      now_year: new Date().getFullYear(),
      selected_year: null,
      begin_date_only: false,
      end_date_only: false,
    }
  },

  computed: {
    remaining_hours() {
      let taken_hours = this.taken_hours('休假')
      let year_leave_hours = this.get_year_leave_hours()

      if (year_leave_hours) {
        return year_leave_hours - taken_hours
      }

      return null
    },

    editing_begin_at: {
      get() {
        let begin_at = this.leave_vstate.editing_leave.begin_at
        return begin_at ? begin_at.toISOString() : ''
      },
      set(editing_begin_at) {
        if (!editing_begin_at) return
        if (this.begin_date_only) {
          // 使用者選了只選日期時，把時間設為上班時間
          this.leave_vstate.editing_leave.begin_at = this.convert_to_commuting_time(new Date(editing_begin_at), 'begin')
        }
        else {
          this.leave_vstate.editing_leave.begin_at = new Date(editing_begin_at)
        }
      }
    },
    editing_end_at: {
      get() {
        let end_at = this.leave_vstate.editing_leave.end_at
        return end_at ? end_at.toISOString() : ''
      },
      set(editing_end_at) {
        if (!editing_end_at) return
        if (this.end_date_only) {
          // 使用者選了只選日期時，把時間設為下班時間
          this.leave_vstate.editing_leave.end_at = this.convert_to_commuting_time(new Date(editing_end_at), 'end')
        }
        else {
          this.leave_vstate.editing_leave.end_at = new Date(editing_end_at)
        }
      }
    },

    year_options() {
      let options = []
      if (this.employee_vstate.leave_status.length) {
        options = [...new Set(this.employee_vstate.leave_status.map(info => info['year']))]
      }
      return options
    },

    save_leave_disabled() {
      let editing_leave = this.leave_vstate.editing_leave
      return !editing_leave || !editing_leave.leave_type || !editing_leave.reason || !(editing_leave.begin_at || editing_leave.end_at)
    }
  },

  watch: {
    selected_year() {
      this.refresh_page_data()
    },

    begin_date_only(begin_date_only) {
      if (begin_date_only) {
        // 使用者在起始時間選了只選日期時，把時間設為上班時間
        let editing_begin_at = this.leave_vstate.editing_leave.begin_at
        if (editing_begin_at) {
          this.leave_vstate.editing_leave.begin_at = this.convert_to_commuting_time(new Date(editing_begin_at), 'begin')
        }
      }
    },

    end_date_only(end_date_only) {
      if (end_date_only) {
        // 使用者在結束時間選了只選日期時，把時間設為下班時間
        let editing_end_at = this.leave_vstate.editing_leave.end_at
        if (editing_end_at) {
          this.leave_vstate.editing_leave.end_at = this.convert_to_commuting_time(new Date(editing_end_at), 'end')
        }
      }
    },

    'employee_vstate.leave_status': {
      handler() {
        // 預設顯示今年的請假內容
        this.selected_year = new Date().getFullYear()
      },
      deep: true
    }
  },

  methods: {
    get_year_leave_hours() {
      let annual_leave_info = this.get_selected_annual_leave_info()
      if (annual_leave_info) {
        return annual_leave_info.base_hours + annual_leave_info.unspent_hours
      }

      return null
    },

    get_selected_annual_leave_info() {
      if (this.employee_vstate.leave_status) {
        return this.employee_vstate.leave_status.find(info => info.year === this.selected_year)
      }
      return null
    },

    taken_hours(leave_type) {
      if (this.leave_vstate.leave_list) {
        let hours = this.leave_vstate.leave_list.filter(info =>
          info['leave_type'] === leave_type
        ).map(info =>
          parseFloat(info['hours'])
        )

        if (hours.length) {
          return hours.reduce((a,b) => a+b)
        }
      }
      return null
    },

    refresh_page_data() {
      this.leave_vstate.get_leave_list(this.selected_year)
    },

    add_leave() {
      this.leave_vstate.editing_leave = {}
      // 用 this.$set 避免某些 getter 不會觸發
      this.$set(this.leave_vstate.editing_leave, 'begin_at', null)
      this.$set(this.leave_vstate.editing_leave, 'end_at', null)
      this.leave_modal_visible = true
      this.begin_date_only = this.end_date_only = false
    },

    edit_leave(leave) {
      this.leave_vstate.editing_leave = {}
      // 用 this.$set 避免某些 getter 不會觸發
      for (let key in leave) {
        this.$set(this.leave_vstate.editing_leave, key, leave[key])
      }

      this.leave_modal_visible = true
      this.date_only = leave.begin_at.getHours() === 0
      this.end_date_only = leave.end_at.getHours() === 23
    },

    save_leave(ok) {
      if (!ok) return  // 若使用者點了取消，ok會是false
      this.leave_vstate.save_leave()
        .then(() => {
          this.refresh_page_data()
        })
    },

    delete_leave(leave) {
      let html_content = '<div>' +
        '假別：' + leave.leave_type + '<br>' +
        '起始：' + this.$datetime_format(leave.begin_at) + '<br>' +
        '結束：' + this.$datetime_format(leave.end_at) + '<br>' +
        '時數：' + leave.hours + '<br>' +
        '事由：' + leave.reason +
        '</div>'

      this.$cb_confirm({
        title: '確定刪除嗎？',
        html_content: html_content
      })
        .then(() => {
          this.leave_vstate.delete_leave(leave.id)
            .then(() => {
              this.refresh_page_data()
            })
        })
        .catch(() => {})
    },

    convert_to_commuting_time(datetime, type) {
      /**
       * 由employee.arrival_time 把 input_datetime 設為上班時間(begin)或下班時間(end)
       * type: 'begin' | 'end'
      */

      let arrival_time = Date.strptime(this.user_vstate.employee.arrival_time, "%H:%M")
      if (type === 'begin') {
        datetime.setHours(arrival_time.getHours())
        datetime.setMinutes(arrival_time.getMinutes())
      }
      else {
        datetime.setHours(arrival_time.addHours(9).getHours())
        datetime.setMinutes(arrival_time.getMinutes())
      }
      return datetime
    },

    wording(value) {
      if (value === null || value === undefined) {
        return '--'
      }
      else {
        return value
      }
    },

    clear_time(key) {
      this.$set(this.leave_vstate.editing_leave, key, null)
    }
  },

  created() {
    this.employee_vstate.get_leave_status()
  }
}
</script>

<style lang="scss" scoped>
@mixin flex_box {
  display: flex;
  flex-wrap: wrap;
}

#main {
  padding: 20px;
  cursor: default
}

.title {
  @include flex_box;
  width: calc(100% - 30px);  // 30px 為預留給info_btn-tutorial的空間
  // heading--h2
  font-size: 2.4rem;
  font-weight: 900;
  letter-spacing: 0.1rem;
}

.annual_info {
  @include flex_box;
  font-size: 14px;
}

.info_btn {
  cursor: pointer;

  &.tutorial {
    font-size: 28px;
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.flex_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0;
}

.hours_block {
  display: flex;

  > div {
    font-size: 12px;
    border-radius: $ex-small;
    padding: 6px 8px;
    border: .1rem solid $color-black--10;
    margin: 5px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    span {
      font-size: 16px;
      margin: 0 5px;
    }
  }
}

.year_select {
  width: 150px;
}

.add_btn {
  font-size: 18px;
  display: flex;
  align-items: center;

  i {
    margin-right: 5px;
  }
}

table {
  @include cb-table;

  th:not(:last-child) {
    min-width: 64px;
  }

  th:last-child {
    width: 40px;
  }
}

@media screen and (max-width: 450px) {
  table {
    th, td {
      font-size: 14px;
      padding: 6px 8px;
    }
    th:not(:last-child) {
      min-width: 58px;
    }
  }

  #main {
    padding: 10px;
  }
}

.delete_btn {
  // TODO: btn_primary 待整理
  background-color: $color-red;
  border-color: $color-red;
  margin: 0;

	&:hover {
		background-color: rgba($color-red, 0.3);
		color: $color-red;
	}
	&:active {
		background-color: rgba($color-red, 1);
		color: $color-white;
  }

  > i {
    padding: 2px;
  }
}

.modal_content_form {
  > div {
    margin-bottom: 5px;

    > h4 {
      // heading--h4, TODO: I
      font-size: 1.4rem;
      font-weight: 700;
      display: flex;
      align-items: center;

      > i {
        margin-left: 5px;
      }
    }
  }
}
.modal_content_list {
  margin-bottom: 10px;
  ol, ul {
    padding-left: 25px;
    margin-left: -4px;
  }
  ol {
    list-style: decimal;
  }

  li {
    margin-bottom: 12px;
  }

  ul {
    list-style: disc;
    font-weight: bold;

    > li li {
      font-weight: normal;
      font-size: 14px;
    }
  }
}

.picker_container {
  display: flex;
  justify-content: flex-end;

    > div {
      width: 100%;
      position: relative;

      > i {
        display: none;
        padding: 10px 0;
        padding-right: 5px;
      }

      &:hover > i {
        display: block;
        position: absolute;
        right: 2px;
        top: 5px;
        cursor: pointer;
      }
    }

  .vdatetime {
    width: 100%;
  }
}

.date_only_btn {
  display: flex;
  align-items: center;
  font-weight: bold;

  > div {
    margin: 0;
    margin-left: 5px;
  }

  span {
    white-space: nowrap;
  }
}
</style>

<style lang="scss">
.vdatetime-input {
  width: 100%;
  cursor: pointer;

  // from input--base
  // TODO: 之後要改include
  border-radius: $ex-small;
  padding: $small $medium;
  border: .1rem solid $color-black--10;
  font-size: $default-font-size;
  color: $color-black--75;
  box-shadow: none;
  margin: $ex-small auto;
  overflow: hidden;

  &:focus {
    outline: none;
    border-color: $color-accent;
  }
  //
}

.leave_modal_card {
  min-width: 300px;
}

.tutorial_modal_card {
  width: 740px;
}

@media screen and (max-width: 770px) {
  .tutorial_modal_card {
    width: calc(100% - 30px);
  }
}
</style>