import Vue from 'vue'
import { EmployeeRepository } from '@/repositories/employee'

const EmployeeVState = new Vue({
    data() {
      return {
        leave_status: [],

        editing_employee: {},
        employee_list: [],

        statistic_list: null,
        annual_leave_list: [],
        editing_annual_leave: {},
      }
    },

    methods: {
      get_employee_list() {
        EmployeeRepository.get_employee_list()
          .then((payload) => {
            this.employee_list = payload.data
          })
          .catch((error) => {
            console.warn(error)
          })
      },

      get_statistic_list(year) {
        EmployeeRepository.get_statistic_list(year)
          .then((payload) => {
            this.statistic_list = payload.data
          })
          .catch((error) => {
            console.warn(error)
          })
      },

      get_leave_status() {
        EmployeeRepository.get_leave_status()
          .then((payload) => {
            this.leave_status = payload.data
          })
          .catch((error) => {
            console.warn(error)
          })
      },

      save_employee() {
        if (this.editing_employee.id === undefined) {
          this.editing_employee.id = null
        }
        EmployeeRepository.save(this.editing_employee)
          .then(payload => {
            this.employee_list = payload.data
          })
          .catch((error) => {
            console.warn(error)
          })
      },

      delete_employee(employee) {
        EmployeeRepository.delete(employee)
          .then((payload) => {
            this.employee_list = payload.data
          })
          .catch((error) => {
            console.warn(error)
          })
      },

      get_annual_leave_list() {
        EmployeeRepository.get_annual_leave_list()
          .then((payload) => {
            this.annual_leave_list = payload.data
          })
          .catch((error) => {
            console.warn(error)
          })
      },
      calc_employee_base_annual_leave(year) {
        return new Promise((resolve, reject) => {
          EmployeeRepository.calc_employee_base_annual_leave(year)
            .then((payload) => {
              resolve()
            })
            .catch((error) => {
              console.warn(error)
              reject(error)
            })
        })
      },

      save_annual_leave(id, unspent_hours) {
        return new Promise((resolve, reject) => {
          EmployeeRepository.save_annual_leave(id, unspent_hours)
            .then((payload) => {
              resolve()
            })
            .catch((error) => {
              console.warn(error)
              reject()
            })
          })
      }
    }
})

export {
    EmployeeVState
  }