/* eslint-disable */
import axios from 'axios'

const leave_axios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_HOST
})

leave_axios.interceptors.response.use(res => {
  return res
})
leave_axios.defaults.headers.get['Accept'] = 'application/json'
leave_axios.defaults.headers.post['Content-Type'] = 'application/json'
leave_axios.defaults.withCredentials = true
leave_axios.defaults.xsrfCookieName = 'csrftoken'
leave_axios.defaults.xsrfHeaderName = 'X-CSRFToken'
leave_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const attach_leave_token_to_axios = () => {
  leave_axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('google_bearer_access_token')
  return leave_axios
}

const LeaveRepository = {
  get_url: function (name, params) {
    if (name === "leave") {
      return "leave/"
    }
    else if (name === "console_leave") {
      return "leave/leave/"
    }
    else if (name === "fetch_national_holiday") {
      return "leave/fetch_national_holiday/"
    }
  },

  get_leave_list: function (year) {
    let params = {
      params: {
        'year': year
      }
    }
    let _url = this.get_url("leave")
    return new Promise((resolve, reject) => {
      attach_leave_token_to_axios()
        .get(_url, params)
        .then(res => {
          let data_list = res.data.data
          for (let data of data_list) {
            data['begin_at'] = new Date(data['begin_at'])
            data['end_at'] = new Date(data['end_at'])
          }
          resolve({
            data: data_list,
            message: res.data.message,
            code: res.status
          })
        })
        .catch(error => {
          let res = error.response
          reject({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
    })
  },

  create_leave(leave) {
    let _url = this.get_url("leave")
    let request_body = leave
    request_body = this._trans_dtime(request_body)
    return new Promise((resolve, reject) => {
      attach_leave_token_to_axios()
        .post(_url, request_body)
        .then(res => {
          resolve({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
        .catch(error => {
          let res = error.response
          reject({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
    })
  },

  update_leave(leave) {
    let _url = this.get_url("leave")
    let request_body = leave
    request_body = this._trans_dtime(request_body)
    return new Promise((resolve, reject) => {
      attach_leave_token_to_axios()
        .patch(_url, request_body)
        .then(res => {
          resolve({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
        .catch(error => {
          let res = error.response
          reject({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
    })
  },

  delete_leave(leave_id) {
    let _url = this.get_url("leave")
    let data = {
      data: {
        id: leave_id
      }
    }
    return new Promise((resolve, reject) => {
      attach_leave_token_to_axios()
        .delete(_url, data)
        .then(res => {
          resolve({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
        .catch(error => {
          let res = error.response
          reject({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
    })
  },

  _trans_dtime(request_body) {
    if (request_body.begin_at) {
      request_body.begin_at = request_body.begin_at.strftime('%Y-%m-%dT%H:%M:%S+08:00')
    }
    if (request_body.end_at) {
      request_body.end_at = request_body.end_at.strftime('%Y-%m-%dT%H:%M:%S+08:00')
    }
    return request_body
  },

  get_console_leave_list: function () {
    let _url = this.get_url("console_leave")
    return new Promise((resolve, reject) => {
      attach_leave_token_to_axios()
        .get(_url)
        .then(res => {
          let data_list = res.data.data
          for (let data of data_list) {
            data['begin_at'] = new Date(data['begin_at'])
            data['end_at'] = new Date(data['end_at'])
          }
          resolve({
            data: data_list,
            message: res.data.message,
            code: res.status
          })
        })
        .catch(error => {
          let res = error.response
          reject({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
    })
  },

  fetch_national_holiday: function (year) {
    let params = {
      params: {
        year: year
      }
    }
    let _url = this.get_url("fetch_national_holiday")
    return new Promise((resolve, reject) => {
      attach_leave_token_to_axios()
        .get(_url, params)
        .then(res => {
          resolve({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
        .catch(error => {
          let res = error.response
          console.error(res)
          reject({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
    })
  },
}

export {
  LeaveRepository
}