import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  UserVState
} from '@/state/user'
import StyleExample from '@/libs/cb-theme/StyleExample'
import Main from '@/views/Main.vue'
import Employee from '@/views/console/Employee.vue'
import Leave from '@/views/console/Leave.vue'
import AnnualLeave from '@/views/console/AnnualLeave.vue'
import Statistic from '@/views/console/Statistic.vue'
import Login from '@/views/Login'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/console/',
    name: 'Employee',
    component: Employee,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/console/leave/',
    name: 'Leave',
    component: Leave,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/console/annual_leave/',
    name: 'AnnualLeave',
    component: AnnualLeave,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/console/statistic/',
    name: 'Statistic',
    component: Statistic,
    meta: {
      requireLogin: true
    }
  },
  /** Account */
  {
    path: '/login/',
    name: 'Login',
    component: Login
  },
]

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/style_example',
    name: 'StyleExample',
    component: StyleExample,
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.requireLogin)) {
    UserVState.verify().then(() => {
      next()
    }).catch(() => {
      next({
        name: 'Login',
        query: {
          next_page: to.path
        }
      })
    })
  } else {
    next()
  }
})

export default router