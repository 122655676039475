/* eslint-disable */
import axios from 'axios'

const account_axios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_HOST
})

account_axios.interceptors.response.use(res => {
  return res
})
account_axios.defaults.headers.get['Accept'] = 'application/json'
account_axios.defaults.headers.post['Content-Type'] = 'application/json'
account_axios.defaults.withCredentials = true
account_axios.defaults.xsrfCookieName = 'csrftoken'
account_axios.defaults.xsrfHeaderName = 'X-CSRFToken'
account_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'


const attach_token_to_axios = () => {
  account_axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('google_bearer_access_token')
  return account_axios
}

const AccountRepository = {
  get_url(name) {
    if (name === "verify") { return "employee/verify/" }
  },

  verify(access_token) {
    let _url = this.get_url("verify")
    return new Promise((resolve, reject) => {
      attach_token_to_axios()
        .post(_url)
        .then(response => {
          resolve({
            data: response.data.data,
            message: response.data.message,
            code: response.status
          })
        })
        .catch(error => {
          reject({
            data: error.response.data.data,
            message: error.response.data.message,
            code: error.response.status
          })
        })
    })
  },
}

export {
  AccountRepository
}