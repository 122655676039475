<template>
  <div id="layout">
    <div id="header_container">
      <nav>
        <div class="nav_bar">
          <a
            class="nav_bar_a"
            :class="route_name === 'Employee' ? 'is_current' : ''"
            @click="$router.push({ name: `Employee`})"
          >員工資料</a>
          <a
            class="nav_bar_a"
            :class="route_name === 'Leave' ? 'is_current' : ''"
            @click="$router.push({ name: `Leave`})"
          >請假紀錄</a>
          <a
            class="nav_bar_a"
            :class="route_name === 'AnnualLeave' ? 'is_current' : ''"
            @click="$router.push({ name: `AnnualLeave`})"
          >特休時數</a>
          <a
            class="nav_bar_a"
            :class="route_name === 'Statistic' ? 'is_current' : ''"
            @click="$router.push({ name: `Statistic`})"
          >假別統計</a>
        </div>
      </nav>
    </div>

    <main ref="main">
      <slot name="main"></slot>
    </main>
    <!-- <resize-observer @notify="layout_resize" /> -->
  </div>
</template>

<script>
export default {
  name: "Layout",

  props: {},
  data() {
    return {
      route_name: ""
    };
  },
  computed: {},
  watch: {},
  methods: {
    layout_resize({ width, height }) {
      let header_container_height = document.getElementById("header_container")
        .clientHeight;
      this.$refs.main.style.height =
        window.innerHeight - header_container_height + "px";
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.route_name = this.$route.name;
      this.layout_resize({});
    });
  }
};
</script>

<style scoped lang="scss">
#layout {
}

#header_container {
  background-color: $color_white;
}

header {
  padding: $medium;
}

nav {
  background-color: $color-black--70;
  color: $color_white;
  font-size: 18px;
  font-weight: bold;
  padding: 0 10px;

  .nav_bar {
    display: flex;
  }

  .nav_bar_a {
    padding: 12px 16px;
    margin: 0 6px;
    cursor: pointer;

    &:hover {
      background-color:$color-accent;
      color: $color-black--70;
      border-radius: 8px;
    }
  }
}

.is_current {
  background-color:$color-accent;
  color: $color-black--70;
  border-radius: 8px;
}

main {
  overflow-y: auto;
  padding: $medium;
}
</style>
