/* eslint-disable */
import axios from 'axios'

const employee_axios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_HOST
})

employee_axios.interceptors.response.use(res => {
  return res
})
employee_axios.defaults.headers.get['Accept'] = 'application/json'
employee_axios.defaults.headers.post['Content-Type'] = 'application/json'
employee_axios.defaults.withCredentials = true
employee_axios.defaults.xsrfCookieName = 'csrftoken'
employee_axios.defaults.xsrfHeaderName = 'X-CSRFToken'
employee_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const attach_employee_token_to_axios = () => {
  employee_axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('google_bearer_access_token')
  return employee_axios
}

function response_wrapper(response) {
  return {
    'data': response.data.data,
    'message': response.data.message,
    'code': response.status
  }
}


const EmployeeRepository = {
  get_url: function (name, params) {
    if (name === "employee") {
      return "employee/"
    }
    else if (name === "statistic") {
      return "employee/statistic/"
    }
    else if (name === "leave_status") {
      return "employee/leave_status/"
    }
    else if (name === "annual_leave") {
      return "employee/annual_leave/"
    }
    else if (name === "calc_employee_base_annual_leave") {
      return "employee/calc_employee_base_annual_leave/"
    }
    else if (name === "update_unspent_hours") {
      return "employee/update_unspent_hours/"
    }
  },

  get_employee_list: function (yer) {
    let params = {
      params: {}
    }
    let _url = this.get_url("employee")
    return new Promise((resolve, reject) => {
      attach_employee_token_to_axios()
        .get(_url, params)
        .then(res => {
          resolve(response_wrapper(res))
        })
        .catch(error => {
          console.error(error)
          reject(response_wrapper(error.response))
        })
    })
  },

  get_statistic_list: function (year) {
    let params = {
      params: {
        year: year
      }
    }
    let _url = this.get_url("statistic")
    return new Promise((resolve, reject) => {
      attach_employee_token_to_axios()
        .get(_url, params)
        .then(res => {
          resolve({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
        .catch(error => {
          console.error(error)
          reject(response_wrapper(error.response))
        })
    })
  },

  get_annual_leave_list: function () {
    let _url = this.get_url("annual_leave")
    return new Promise((resolve, reject) => {
      attach_employee_token_to_axios()
        .get(_url)
        .then(res => {
          resolve(response_wrapper(res))
        })
        .catch(error => {
          console.error(error)
          reject(response_wrapper(error.response))
        })
    })
  },

  calc_employee_base_annual_leave: function (year) {
    let params = {
      params: {
        year: year
      }
    }
    let _url = this.get_url("calc_employee_base_annual_leave")
    return new Promise((resolve, reject) => {
      attach_employee_token_to_axios()
        .get(_url, params)
        .then(res => {
          resolve(response_wrapper(res))
        })
        .catch(error => {
          console.error(error)
          reject(response_wrapper(error.response))
        })
    })
  },

  get_leave_status: function () {
    let _url = this.get_url("leave_status")
    return new Promise((resolve, reject) => {
      attach_employee_token_to_axios()
        .get(_url)
        .then(res => {
          resolve({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
        .catch(error => {
          reject(response_wrapper(error.response))
        })
    })
  },

  save: function(data) {
    let _url = 'employee/'
    return new Promise((resolve, reject) => {
      attach_employee_token_to_axios()
        .post(_url, data)
        .then(res => {
          if (res.data) {
            resolve(response_wrapper(res))
          }
        })
        .catch(error => {
          console.error(error)
          reject(response_wrapper(error.response))
        })
    })
  },

  delete: function(employee) {
    let _url = 'employee/'
    let data = {
      data: {
        id: employee.id
      }
    }
    return new Promise((resolve, reject) => {
      attach_employee_token_to_axios()
        .delete(_url, data)
        .then(res => {
          resolve(response_wrapper(res))
        })
        .catch(error => {
          reject(response_wrapper(error.response))
        })
    })
  },

  save_annual_leave: function(id, unspent_hours) {
    let params = {
      params: {
        'id': id,
        'unspent_hours': unspent_hours
      }
    }
    let _url = this.get_url("update_unspent_hours")
    return new Promise((resolve, reject) => {
      attach_employee_token_to_axios()
        .get(_url, params)
        .then(res => {
          resolve({
            data: res.data.data,
            message: res.data.message,
            code: res.status
          })
        })
        .catch(error => {
          console.error(error)
          reject(response_wrapper(error.response))
        })
    })
  }
}


export {
  EmployeeRepository
}