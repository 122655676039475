import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/libs/cb-static/cb.util'
import '@/libs/cb-static/sprintf.min'
import '@/libs/cb-static/cb.date'
import '@/libs/cb-theme'

import { Datetime } from '@/libs/vue-datetime/vue-datetime';
import '@/libs/vue-datetime/vue-datetime.css'
Vue.component('datetime', Datetime)

Vue.prototype.$datetime_format = (datetime) => {
  return datetime.strftime('%Y-%m-%d %H:%M')
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
