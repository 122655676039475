<template>
  <Layout>
    <template v-slot:main>
      <div>
        <div class="flex_row">
          <div class="year_select">
            <div class="flex_box container_select--base">
              <select
                v-model="selected_year"
                class="column_full input--base"
              >
                <option :value="null">所有年份</option>
                <option
                  v-for="(year, idx) in year_options"
                  :key="'year_' + idx"
                  :value="year"
                >{{ year }}</option>
              </select>
              <i class="icon-arrow_down"></i>
            </div>
          </div>
          <div class="btn_sets">
            <div class="flex_box container_input--base">
              <input
                class="column_full input--base"
                placeholder="請輸入要計算的年份"
                type="number"
                v-model="calc_annaul_leave_year"
              >
            </div>
            <button
              class="status_btn btn_primary circle_radius"
              :loading="calc_annaul_leave_loading"
              :disabled="calc_annaul_leave_loading"
              @click="calc_employee_base_annual_leave"
            >
              <span>
                <i class="icon-edit-1"></i>
                計算基準時數
              </span>
              <div></div>
            </button>
            <button
              class="status_btn btn_primary circle_radius"
              :loading="import_holidays_loading"
              :disabled="import_holidays_loading"
              @click="fetch_national_holiday"
            >
              <span>
                <i class="icon-edit-2"></i>
                匯入國定假日
              </span>
              <div></div>
            </button>
            <button
              class="status_btn btn_primary circle_radius"
              @click="rule_visible = true"
            >
              <span>
                說明
              </span>
              <div></div>
            </button>
          </div>
        </div>
        <table>
          <tr>
            <th>年度</th>
            <th>員工</th>
            <th>基準時數</th>
            <th>剩餘時數</th>
            <th>轉移時數</th>
          </tr>
          <tr
            v-if="!annual_leave_list || !annual_leave_list.length"
            class="empty_tr"
          >
            <td colspan="5">
              暫無資料
            </td>
          </tr>
          <tr
            v-for="(annual_info, idx) in annual_leave_list"
            :key="'annual_info_' + idx"
            @click="edit_annual_leave(annual_info)"
          >
            <td>{{ annual_info.year }}</td>
            <td>{{ annual_info.employee }}</td>
            <td>{{ annual_info.base_hours }}</td>
            <!-- 剩餘時數 =  基準時數 + 轉移時數 - 已休時數-->
            <td>{{ annual_info.base_hours + annual_info.unspent_hours - annual_info.taken_hours }}</td>
            <td>{{ annual_info.unspent_hours }}</td>
          </tr>
        </table>
        <Modal
          :visible.sync="rule_visible"
          title="歷年操作說明"
          closable
          mask_closable
        >
          <template v-slot:content>
            <div>請假系統管理使用說明</div>
            <div>1.「特休時數」介面中，左側年份選單為管理者選擇欲查看之年度時數概況。</div>
            <div>2.「輸入要計算的年份」後點選「計算基準時數」按鈕，下方表單即顯示人員當年可用的特休時數。（隨時可以計算）</div>
            <div>3.「輸入要計算的年份」後點選「匯入國定假日」按鈕（包含六日），當人員請假時，系統會自動依據匯入之資料，於國定假日請假的時數不予計算。（需於政府公告當年度國定假日時程，才能進行匯入）</div>
            <div>4.確認顯示年份後，點選該人員「轉移時數」欄位中的數字，即可編輯去年將移轉至今年的特休時數。</div>
            <div>5.「計算基準時數」及「匯入國定假日」不用照順序按，哪個先按都可以，重複按也不會有問題，但如果有寫「轉移時數」，又再重新按「計算基準時數」轉移時數會被清掉。</div>
          </template>
          <template v-slot:footer>
            <div></div>
          </template>
        </Modal>

        <Modal
          :visible.sync="modal_visible"
          :title="employee_vstate.editing_annual_leave.id === undefined ? '新增年度時數' : '編輯年度時數'"
          @confirm="save_annual_leave"
          closable
          mask_closable
        >
          <template v-slot:content>
            <div class="modal_content">
              <div>
                年度
                <span>{{ employee_vstate.editing_annual_leave.year }}</span>
              </div>
              <div>
                人員
                <span>{{ employee_vstate.editing_annual_leave.employee }}</span>

              </div>
              <div>
                基準時數
                <span>{{ employee_vstate.editing_annual_leave.base_hours }}</span>
              </div>
              <div>
                轉移時數
                <div class="flex_box container_input--base">
                  <input
                    type="number"
                    v-model="employee_vstate.editing_annual_leave.unspent_hours"
                    class="column_full input--base"
                  >
                </div>
              </div>
            </div>
          </template>
        </Modal>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import { EmployeeVState } from '@/state/employee'
import { LeaveVState } from '@/state/leave'

export default {
  name: 'AnnualLeave',

  components: {
    Layout,
  },

  data() {
    return {
      employee_vstate: EmployeeVState,
      leave_vstate: LeaveVState,

      modal_visible: false,
      calc_annaul_leave_loading: false,
      import_holidays_loading: false,

      selected_year: null,  // 預設為 All
      calc_annaul_leave_year: null,
      rule_visible: false,
    }
  },

  computed: {
    year_options() {
      let options = []
      if (this.employee_vstate.annual_leave_list.length) {
        options = [...new Set(this.employee_vstate.annual_leave_list.map(info => info['year']))]
      }
      return options
    },

    annual_leave_list() {
      if (this.selected_year) {
        return this.employee_vstate.annual_leave_list.filter(info => info['year'] === this.selected_year)
      }
      else {
        return this.employee_vstate.annual_leave_list
      }
    }
  },

  methods: {

    get_annual_leave_list() {
      this.employee_vstate.get_annual_leave_list()
    },

    edit_annual_leave(leave) {
      this.employee_vstate.editing_annual_leave = Object.deepAssign({}, leave)
      this.modal_visible = true
    },

    save_annual_leave() {
      this.employee_vstate.save_annual_leave(this.employee_vstate.editing_annual_leave.id, this.employee_vstate.editing_annual_leave.unspent_hours)
        .then(() => {
          this.get_annual_leave_list()
        })
    },

    calc_employee_base_annual_leave() {
      this.calc_annaul_leave_loading = true
      this.employee_vstate.calc_employee_base_annual_leave(this.calc_annaul_leave_year)
        .then(() => {
          this.$cb_alert('基準時數已計算')
          this.get_annual_leave_list()
          this.calc_annaul_leave_loading = false
        })
        .catch((error) => {
          this.$alert(error.message)
          this.calc_annaul_leave_loading = false
        })
    },

    fetch_national_holiday() {
      this.import_holidays_loading = true
      this.leave_vstate.fetch_national_holiday(this.calc_annaul_leave_year)
        .then((payload) => {
          let holiday_year = payload.data.year
          let is_import_holidays = payload.data.is_import_holidays
          if (is_import_holidays) {
            this.$cb_alert(holiday_year + '年國定假日已匯入資料庫')
          } else {
            this.$cb_alert(holiday_year + '年國定假日尚未公布')
          }
          this.import_holidays_loading = false
        })
        .catch((error) => {
          this.$alert(error.message)
          this.import_holidays_loading = false
        })
    }
  },

  created() {
    this.get_annual_leave_list()
  }
}
</script>

<style lang="scss" scoped>
.flex_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.year_select {
  width: 150px;
}

.btn_sets {
  display: flex;
}

table {
  @include cb-table;
  margin: 10px 0;
}

.modal_content {
  > div {
    text-align: left;
    margin-bottom: 5px;
    min-height: 50px;

    // heading--h4, TODO: I
    font-size: 1.4rem;
    font-weight: 700;

    > span {
      font-size: 18px;
      display: block;
    }
  }
}
</style>