import Vue from 'vue'

/** import scss */
// import "@/libs/cb-theme/scss/style.scss"

/** register Modal */
import Modal from '@/libs/cb-theme/Modal'
Vue.component('Modal', Modal)

/** register Dialog */
import Dialog from '@/libs/cb-theme/Dialog.vue'
const DialogComponent = Vue.extend(Dialog)
const dialog = new DialogComponent().$mount()

Vue.prototype.$cb_confirm = (options) => {
  let _options = options instanceof Object ? options : { content: options }
  Object.deepAssign(dialog, {
    type: 'confirm',
    title: '確認'
  }, _options)
  document.body.appendChild(dialog.$el)
  return dialog.launch()
}

Vue.prototype.$cb_alert = (options) => {
  let _options = options instanceof Object ? options : { content: options }
  Object.deepAssign(dialog, {
    type: 'alert',
    title: '通知'
  }, _options)
  document.body.appendChild(dialog.$el)
  return dialog.launch()
}