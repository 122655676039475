<template>
  <Layout>
    <template v-slot:main>
      <div>
        <div class="year_select">
          <label class="flex_box container_select--base">
            <select
              v-model="selected_year"
              class="column_full input--base"
            >
              <option
                v-for="(year, idx) in year_options"
                :key="'year_' + idx"
                :value="year"
              >{{ year }}</option>
            </select>
            <i class="icon-arrow_down"></i>
          </label>
        </div>
        <table>
          <tr>
            <th>員工</th>
            <th
              v-for="(leave_type, idx) in leave_types"
              :key="'leave_type_header_' + idx"
            >
              {{ leave_type }}
            </th>
          </tr>
          <tr
            v-for="(statistic, idx) in employee_vstate.statistic_list"
            :key="'statistic_' + idx"
          >
            <td>{{ statistic.employee }}</td>
            <td
              v-for="(leave_type, idx) in leave_types"
              :key="'leave_type_' + idx"
            >
              {{ statistic.data[leave_type] ? statistic.data[leave_type] : 0 }}
            </td>
          </tr>
        </table>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import { EmployeeVState } from '@/state/employee'
import { LEAVE_TYPES } from '@/settings'

export default {
  name: 'Statistic',

  components: {
    Layout,
  },

  data() {
    return {
      employee_vstate: EmployeeVState,
      leave_types: LEAVE_TYPES,

      selected_year: null,
    }
  },

  computed: {
    year_options() {
      let options = []
      if (this.employee_vstate.annual_leave_list.length) {
        options = [...new Set(this.employee_vstate.annual_leave_list.map(info => info['year']))]
      }
      return options
    },
  },

  watch: {
    selected_year() {
      this.employee_vstate.get_statistic_list(this.selected_year)
    },

    'employee_vstate.annual_leave_list': {
      handler() {
        this.selected_year = this.employee_vstate.annual_leave_list[0].year
      }
    }
  },

  methods: {
  },

  mounted() {
    this.employee_vstate.get_annual_leave_list()
  },
}
</script>

<style lang="scss" scoped>
.year_select {
  width: 150px;
}

table {
  @include cb-table(false);
  margin: 10px 0;

  tr > td:first-child {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>