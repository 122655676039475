import Vue from 'vue'
import { LeaveRepository } from '@/repositories/leave'

const LeaveVState = new Vue({
  data() {
    return {
      editing_leave: {},
      leave_list: null,
      console_leave_list: []
    }
  },

  methods: {
    get_leave_list(year) {
      LeaveRepository.get_leave_list(year)
        .then((payload) => {
          this.leave_list = payload.data
        })
        .catch((error) => {
          console.warn(error)
        })
    },

    save_leave() {
      if (this.editing_leave.id === undefined) {
        // to create
        return new Promise((resolve, reject) => {
          // use deepAssign to avoid value changed in repository
          LeaveRepository.create_leave(Object.deepAssign({}, this.editing_leave))
            .then((payload) => {
              this.editing_leave = null
              resolve()
            })
            .catch((error) => {
              console.warn(error)
              alert(error.message)
              reject()
            })
        })
      }
      else {
        // to update
        return new Promise((resolve, reject) => {
          // use deepAssign to avoid value changed in repository
          LeaveRepository.update_leave(Object.deepAssign({}, this.editing_leave))
            .then((payload) => {
              this.editing_leave = null
              resolve()
            })
            .catch((error) => {
              console.warn(error)
              reject()
            })
        })
      }
    },

    delete_leave(leave_id) {
      return new Promise((resolve, reject) => {
        LeaveRepository.delete_leave(leave_id)
          .then((payload) => {
            resolve()
          })
          .catch((error) => {
            console.warn(error)
            reject()
          })
      })
    },

    get_console_leave_list() {
      LeaveRepository.get_console_leave_list()
        .then((payload) => {
          this.console_leave_list = payload.data
        })
        .catch((error) => {
          console.warn(error)
        })
    },

    fetch_national_holiday(year) {
      return new Promise((resolve, reject) => {
        LeaveRepository.fetch_national_holiday(year)
          .then((payload) => {
            resolve(payload)
          })
          .catch((error) => {
            console.warn(error)
            reject(error)
          })
      })
    }
  }
})

export {
  LeaveVState
}