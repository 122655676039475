<template>
  <div
    v-show="visible"
    class="modal-container"
    @click.self="mask_closable ? _toggle(false) : null"
  >

    <div class="modal-card card--float" :class="card_style">
      <header class="modal-header">
        <slot name="header">
          <h1 class="heading--h3">
            {{ title }}
          </h1>
        </slot>
      </header>

      <i
        v-show="closable"
        class="icon-symbol_close modal-close_btn"
        @click="_toggle(false)"
      >
      </i>

      <main>
        <div class="modal-content">
          <slot name="content">
            <div v-if="html_content" v-html="html_content"></div>
            <div v-else class="text_content">
              {{ content }}
            </div>
          </slot>
        </div>

        <slot name="footer">
          <div class="double_btn_group">
            <button
              v-if="cancel_btn_required"
              class="btn_cancel"
              @click="_cancel"
            >
              {{ cancel_text }}
            </button>
            <slot name="ok_btn">
              <button
                class="btn_primary status_btn"
                :disabled="loading || disabled"
                :loading="loading"
                @click="_ok"
              >
                <span>
                  {{ ok_text }}
                </span>
                <div></div>
              </button>
            </slot>
          </div>
        </slot>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    // 顯示/隱藏modal，外層用 :visible.sync 控制
    visible: {
      type: Boolean,
      default: false
    },

    // 標題
    title: {
      type: String,
      default: "標題"
    },

    // 內容
    content: {
      type: String,
      default: "內容"
    },
    // html格式的內容
    html_content: {
      type: String,
      default: null
    },
    // P.S. 傳入內容的優先度：slot > html_content > content

    // 確認按鈕的文字
    ok_text: {
      type: String,
      default: "確定"
    },

    // 取消按鈕的文字
    cancel_text: {
      type: String,
      default: "取消"
    },

    // 是否需要取消按鈕
    cancel_btn_required: {
      type: Boolean,
      default: false
    },

    // 確認按鈕的狀態：disabled and loading，若有使用到則需由外層手動關閉modal
    // 若不希望點選ok直接關閉，也可以將loading設為false，由外部關閉
    loading: {
      type: Boolean,
      default: null
    },

    // 確認按鈕的狀態：disabled，用在content中有必填欄位的情況
    disabled: {
      type: Boolean,
      default: false
    },

    // 是否有右上角關閉按鈕
    closable: {
      type: Boolean,
      default: false
    },

    // 是否能點選周圍遮罩關閉
    mask_closable: {
      type: Boolean,
      default: false
    },

    // 是否能按esc關閉
    esc_to_close: {
      type: Boolean,
      default: false
    },

    // 是否能按enter確定
    enter_to_ok: {
      type: Boolean,
      default: false
    },

    // 自訂card的style class
    card_style: {
      type: String,
      default: null
    }
  },

  methods: {
    _cancel() {
      this.$emit('confirm', false)
      this._toggle(false)
    },

    _ok() {
      this.$emit('confirm', true)
      if (this.loading === null) { // 外層沒使用到loading，自動關閉modal
        this._toggle(false)
      }
    },

    _toggle(visible) {
      this.$emit('update:visible', visible)
    }
  },

  created() {
    // esc 關閉
    if (this.esc_to_close) {
      document.addEventListener("keyup", (e) => {
        if (e.key === "Escape" && this.visible) {
          this._toggle(false)
        }
      })
    }
    // enter 確定
    if (this.enter_to_ok) {
      document.addEventListener("keyup", (e) => {
        if (e.key === "Enter" && this.visible) {
          this._ok()
        }
      })
    }
  }
}
</script>

<style lang="scss" scopde>

.modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background-color: rgba($color-black, 0.3);
  text-align: center;
}

.modal-card {
  @include container-width(12, 11);
  top: $ex-large;
  padding: 0 $medium !important;
  position: relative;
}

@media screen and (min-width: 600px) {
  .modal-card {
    @include container-width(12, 6);
    padding: 0 $large !important;
  }
}
@media screen and (min-width: 930px) {
  .modal-card {
    @include container-width(12, 4);
    padding: 0 $large !important;
  }
}

.modal-header {
  text-align: center;
  margin: 16px 0;
}

.modal-content {
  text-align: left;
  max-height: calc(100vh - 320px);  // 減去header+footer以及其margin，還有ios手機可能長出的footer
  margin: $small 0;
  overflow: scroll;
}
.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-close_btn {
  font-size: 20px;
  color: $color-black--40;
  position: absolute;
  top: $medium;
  right: $medium;
  cursor: pointer;
}

.text_content {
  text-align: center;
  margin-top: $small;
  margin-bottom: $medium;
  font-size: 18px;
  letter-spacing: 1px;
}
</style>>