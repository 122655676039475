<template>
  <Layout>
    <template v-slot:main>
      <div id="leave">
        <div class="selector_block">
          <div class="year_selector">
            <label class="flex_box container_select--base">
              <select
                v-model="selected_year"
                class="column_full input--base"
              >
                <option :value="null">所有年份</option>
                <option
                  v-for="(year, idx) in year_options"
                  :key="'year_' + idx"
                  :value="year"
                >
                {{ year }}
                </option>
              </select>
              <i class="icon-arrow_down"></i>
            </label>
          </div>
          <div class="employee_selector">
            <label class="flex_box container_select--base">
              <select
                v-model="selected_employee"
                class="column_full input--base"
              >
                <option selected :value="null">所有人員</option>
                <option
                  v-for="(employee, idx) in employee_vstate.employee_list"
                  :key="'employee_' + idx"
                  :value="employee.nickname"
                >
                {{ employee.nickname }}
                </option>
              </select>
              <i class="icon-arrow_down"></i>
            </label>
          </div>
        </div>
        <table>
          <tr>
            <th>假別</th>
            <th>員工</th>
            <th>起始</th>
            <th>結束</th>
            <th>時數</th>
          </tr>
          <tr
            v-if="!year_console_leave_list.length"
            class="empty_tr"
          >
            <td colspan="5">
              暫無資料
            </td>
          </tr>
          <tr
            v-for="(leave, idx) in year_console_leave_list"
            :key="'leave_' + idx"
          >
            <td>{{ leave.leave_type }}</td>
            <td>{{ leave.employee }}</td>
            <td>{{ $datetime_format(leave.begin_at) }}</td>
            <td>{{ $datetime_format(leave.end_at) }}</td>
            <td>{{ leave.hours}}</td>
          </tr>
        </table>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import { LeaveVState } from '@/state/leave'
import { EmployeeVState } from '@/state/employee'

export default {
  components: {
    Layout,
  },
  data() {
    return {
      leave_vstate: LeaveVState,
      employee_vstate: EmployeeVState,
      selected_year: null,
      selected_employee: null,
    }
  },

  computed: {
    year_console_leave_list() {
      let data = []

      if (this.leave_vstate.console_leave_list) {
        if (this.selected_year === null) {
          data = this.leave_vstate.console_leave_list
        }
        else {
          data = this.leave_vstate.console_leave_list.filter(info => info['begin_at'].getFullYear() === this.selected_year)
        }

        if (this.selected_employee) {
          data = data.filter(info => info['employee'] === this.selected_employee)
        }
      }

      return data
    },

    year_options() {
      let options = []
      if (this.leave_vstate.console_leave_list) {
        options = [...new Set(this.leave_vstate.console_leave_list.map(info => info['begin_at'].getFullYear()))]
      }
      return options
    }
  },

  watch: {
    'leave_vstate.console_leave_list': {
      handler() {
        this.selected_year = this.leave_vstate.console_leave_list[0]['begin_at'].getFullYear()
      },
      deep: true
    }
  },

  methods: {
    get_console_leave_list() {
      this.leave_vstate.get_console_leave_list()
    }
  },

  mounted() {
    this.get_console_leave_list()
    this.employee_vstate.get_employee_list()
  }
}


</script>
<style lang="scss" scoped>
table {
  @include cb-table(false);
  margin: 10px 0;
}

.selector_block {
  display: flex;

  > div {
    width: 150px;
    margin-right: 10px;
  }
}

</style>