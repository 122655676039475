<template>
  <Layout>
    <template v-slot:main>
      <div>
        <!-- <span>員工基本資訊</span> -->
        <!-- <div>
        </div> -->
        <div class="flex_row">
          <button
            class="btn_primary circle_radius add_btn"
            @click="add_employee"
          >
          <i class="icon-symbol_add"></i>
            新增
          </button>
        </div>
        <table>
          <tr>
            <th>Email</th>
            <th>名字</th>
            <th>到職日期</th>
            <th>習慣到班時間</th>
            <th></th>
          </tr>
          <tr
            v-for="(employee, idx) in employee_vstate.employee_list"
            :key="'employee_' + idx"
            @click="edit_employee(employee)"
          >
            <td>{{ employee.email }}</td>
            <td>{{ employee.nickname }}</td>
            <td>{{ employee.on_board }}</td>
            <td>{{ employee.arrival_time }}</td>
            <td>
              <button
                class="btn_primary btn_icon delete_btn circle_radius"
                @click.stop="delete_employee(employee)"
              >
                <i class="icon-symbol_subtract"></i>
              </button>
            </td>
          </tr>
        </table>
      </div>
      <Modal
        :visible.sync="modal_visible"
        title="新增人員"
        closable
        mask_closable
        @confirm="save_employee"
      >
        <template v-slot:content>
          <div class="modal_content">
            <div>
              Mail
              <div class="flex_box container_input--base">
                <input
                  v-model="employee_vstate.editing_employee.email"
                  class="column_full input--base"
                >
              </div>
            </div>
            <div>
              姓名
              <div class="flex_box container_input--base">
                <input
                  v-model="employee_vstate.editing_employee.nickname"
                  class="column_full input--base"
                >
              </div>
            </div>
            <div>
              到職日期
              <datetime
                v-model="editing_on_board"
                type="date"
                :auto=true
                format="yyyy-MM-dd"
              >
              </datetime>
            </div>
            <div>
              習慣到班時間
              <datetime
                v-model="editing_arrival_time"
                type="time"
                :minuteStep="30"
                :auto=true
                format="HH:mm"
              >
              </datetime>
            </div>
          </div>
        </template>
      </Modal>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import { EmployeeVState } from '@/state/employee'

export default {
  data() {
    return {
      employee_vstate: EmployeeVState,

      modal_visible: false,
    }
  },

  components: {
    Layout,
  },

  computed: {
    editing_on_board: {
      get() {
        let on_board = this.employee_vstate.editing_employee.on_board
        return on_board ? Date.strptime(on_board, "%Y-%m-%d").toISOString() : ''
      },
      set(editing_on_board) {
        if (!editing_on_board) return
        this.employee_vstate.editing_employee.on_board = new Date(editing_on_board).strftime("%Y-%m-%d")
      }
    },

    editing_arrival_time: {
      get() {
        let arrival_time = this.employee_vstate.editing_employee.arrival_time
        return arrival_time ? Date.strptime(arrival_time, "%H:%M").toISOString() : ''
      },
      set(editing_arrival_time) {
        if (!editing_arrival_time) return
        this.employee_vstate.editing_employee.arrival_time = new Date(editing_arrival_time).strftime("%H:%M")
      }
    },
  },

  methods: {
    add_employee() {
      this.employee_vstate.editing_employee = {}
      // 用 this.$set 避免某些 getter 不會觸發
      this.$set(this.employee_vstate.editing_employee, 'on_board', null)
      this.$set(this.employee_vstate.editing_employee, 'arrival_time', null)
      this.modal_visible = true
    },

    edit_employee(employee) {
      this.employee_vstate.editing_employee = {}
      // 用 this.$set 避免某些 getter 不會觸發
      for (let key in employee) {
        this.$set(this.employee_vstate.editing_employee, key, employee[key])
      }
      this.modal_visible = true
    },

    save_employee(ok) {
      if (!ok) return
      this.employee_vstate.save_employee()
    },

    delete_employee(employee) {
      this.$cb_confirm({
        title: "確定刪除嗎？",
        content: "員工名字：" + employee.nickname
      })
        .then(() => {
          this.employee_vstate.delete_employee(employee)
        })
        .catch(() => {})
    }
  },

  created() {
    this.employee_vstate.get_employee_list()
  }
}
</script>
<style lang="scss" scoped>
.flex_row {
  display: flex;
  justify-content: flex-end;
}

.add_btn {
  font-size: 18px;
  display: flex;
  align-items: center;

  i {
    margin-right: 5px;
  }
}

table {
  @include cb-table;
  margin: 10px 0;

  th:last-child {
    width: 40px;
  }
}

.modal_content {
  > div {
    text-align: left;
    margin-bottom: 5px;

    // heading--h4, TODO: I
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.delete_btn {
  // TODO: btn_primary 待整理
  background-color: $color-red;
  border-color: $color-red;
  margin: 0;

	&:hover {
		background-color: rgba($color-red, 0.3);
		color: $color-red;
	}
	&:active {
		background-color: rgba($color-red, 1);
		color: $color-white;
  }

  > i {
    padding: 2px;
  }
}
</style>