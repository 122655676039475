<template>
  <form ref="form" :action="oauth2Endpoint" method="get"></form>
</template>

<script>
import axios from 'axios'

import { UserVState } from '@/state/user'

const _axios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_HOST
})

export default {
  data() {
    return {
      oauth2Endpoint: 'https://accounts.google.com/o/oauth2/v2/auth'
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        const parsed_params = {};
        this.$route.hash.split('&')
          .map(part => part.replace(/#/, ''))
          .forEach(param => {
            const parts = param.split('=')
            parsed_params[parts[0]] = parts[1]
          })

        localStorage.setItem('google_bearer_access_token', `${parsed_params.access_token}`)
        if (parsed_params.state) {
          this.$router.replace(parsed_params.state)
        } else {
          this.$router.replace('/')
        }
      } else {
        const google_auth_uri = 'https://accounts.google.com/o/oauth2/v2/auth?',
          redirect_uri = process.env.VUE_APP_REDIRECT_URI,
          profile_scope = 'https://www.googleapis.com/auth/userinfo.profile',
          email_scope = 'https://www.googleapis.com/auth/userinfo.email',
          send_email_scope = 'https://www.googleapis.com/auth/gmail.compose',
          calendar_scope = 'https://www.googleapis.com/auth/calendar.events',
          client_id = '429830725203-vert8tphjr7l23qiaek0tqc7omerhuc6.apps.googleusercontent.com'

        // Parameters to pass to OAuth 2.0 endpoint.
        const params = {
          'client_id': client_id,
          'redirect_uri': redirect_uri,
          'response_type': 'token',
          'scope': `${email_scope} ${profile_scope} ${send_email_scope} ${calendar_scope}`,
          'include_granted_scopes': 'true',
          'state': this.$route.query.next_page,
          // 'prompt': 'consent'
        }

        for (var p in params) {
          var input = document.createElement('input')
          input.setAttribute('type', 'hidden')
          input.setAttribute('name', p)
          input.setAttribute('value', params[p])
          this.$refs.form.appendChild(input)
        }

        this.$refs.form.submit()
      }
    })
  }
}
</script>
