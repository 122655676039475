<template>
  <main class="style_example">

    <!-- VI_style_primary：主視覺樣式 -->
    <article class="vi_primary">
      <!-- Header區塊：專案Logo展示 -->
      <!-- Header區塊：排版展示 -->
      <section class="flex_box section_header">
        <div class="flex_box column_half container_row">
          <!-- <img alt="網站logo" src="./imgs/logo.png" class="logo"> -->
          <div>
            <h1 class="heading--h1--sub">Logo副標題</h1>
            <h1 class="heading--h1">Logo主標題</h1>
          </div>
        </div>
        <div class="flex_box column_half container_cloumn">
          <!-- <img alt="網站logo" src="./imgs/logo.png" class="logo"> -->
          <h1 class="heading--h1--sub">Logo副標題</h1>
          <h1 class="heading--h1">Logo主標題</h1>
        </div>
      </section>
      <hr class="separate_line">

      <!-- Typography guidline：標題與文字規範 -->
      <section class="section_typography">
        <h2 class="heading--h4 decoration_line_for_section_title">Typography&nbsp;guidline</h2>
        <div class="margin_bottom--medium">
          <!-- 主標題/副標題 -->
          <h1 class="heading--h1">主標題h1-32px&nbsp;(class="heading--h1")</h1>
          <h1 class="heading--h1--sub">副標題h1-24px&nbsp;(class="heading--h1--sub")</h1>
        </div>
        <div class="margin_bottom--medium">
          <!-- 大標題 -->
          <h2 class="heading--h2">大標題h2-26px&nbsp;(class="heading--h2")</h2>
        </div>
        <div class="margin_bottom--medium">
          <!-- 中標題 -->
          <h3 class="heading--h3">中標題h3-20px&nbsp;(class="heading--h3")</h3>
        </div>
        <div class="margin_bottom--medium">
          <!-- 小標題 -->
          <h4 class="heading--h4">小標題h4-18px&nbsp;(class="heading--h4")</h4>
        </div>
        <div class="margin_bottom--medium">
          <!-- 內文-預設(網頁預設字型大小請修改'$default_font-size') -->
          <span>內文-預設-16px</span>
        </div>
        <div class="margin_bottom--medium">
          <!-- 內文(小) -->
          <span class="content_text--small">內文-小-12px&nbsp;(class="content_text--small")</span>
        </div>
      </section>
      <hr class="separate_line">

      <!-- Color guidline：色彩規範 -->
      <section>
        <div class="margin_bottom--large colors_guidline">
          <h2 class="heading--h4 decoration_line_for_section_title">Colors&nbsp;style&nbsp;guidline</h2>
          <div class="flex_box">
            <!-- 此為展示處，請至scss/abstracts/_variable.scss取用色彩 -->
            <div class="flex_box column_half margin_bottom--ex-small container_color">
              <div class="column_full margin_bottom--ex-small color-box color-box--primary">
                <div class="margin_bottom--medium heading--h2">
                  $color-primary
                </div>
                <div class="flex_box">
                  <span>#79C7BF</span>
                  <span>rgb(121, 199, 191)</span>
                </div>
              </div>
              <div class="column_full color-box color-box--primary--30">
                <div class="margin_bottom--ex-small">
                  $color-primary--30
                </div>
                <div class="flex_box content_text--small ">
                  <span>#D6EEEC</span>
                  <span>rgb(214, 238, 236)</span>
                </div>
              </div>
              <div class="column_full color-box color-box--primary--70">
                <div class="margin_bottom--ex-small">
                  $color-primary--70
                </div>
                <div class="flex_box content_text--small ">
                  <span>#A1D7D2</span>
                  <span>rgb(161, 215, 210)</span>
                </div>
              </div>
              <div class="column_full color-box color-box--primary--heavy">
                <div class="margin_bottom--ex-small">
                  $color-primary--heavy
                </div>
                <div class="flex_box content_text--small ">
                  <span>#48A59C</span>
                  <span>rgb(72, 165, 156)</span>
                </div>
              </div>
            </div>
            <div class="flex_box column_half margin_bottom--ex-small container_color">
              <div class="column_full margin_bottom--ex-small color-box color-box--accent">
                <div class="margin_bottom--medium heading--h2 ">
                  $color-accent
                </div>
                <div class="flex_box">
                  <span>#E7D34C</span>
                  <span>rgb(231, 221, 76)</span>
                </div>
              </div>
              <div class="column_full color-box color-box--accent--40">
                <div class="margin_bottom--ex-small">
                  $color-accent--30
                </div>
                <div class="flex_box content_text--small ">
                  <span>#F8F3CD</span>
                  <span>rgb(248, 243, 205)</span>
                </div>
              </div>
              <div class="column_full color-box color-box--accent--70">
                <div class="margin_bottom--ex-small">
                  $color-accent--70
                </div>
                <div class="flex_box content_text--small ">
                  <span>#EFE28A</span>
                  <span>rgb(239, 226, 138)</span>
                </div>
              </div>
              <div class="column_full color-box color-box--accent--heavy">
                <div class="margin_bottom--ex-small">
                  $color-accent--heavy
                </div>
                <div class="flex_box content_text--small ">
                  <span>#D4B700</span>
                  <span>rgb(212, 183, 0)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="base_guideline">
          <h2 class="heading--h4 decoration_line_for_section_title">Base&nbsp;Colors&nbsp;style</h2>
          <div class="flex_box">
            <!-- 此為展示處，請至scss/abstracts/_variable.scss取用色彩 -->
            <div class="flex_box column_full margin_bottom--ex-small container_color">
              <div class="column_full margin_bottom--ex-small color-box color-box--black">
                <div class="margin_bottom--medium heading--h2 ">
                  $color-black
                </div>
                <div class="flex_box">
                  <span>#000000</span>
                  <span>rgb(0, 0, 0)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--80">
                <div class="margin_bottom--ex-small">
                  $color-black--80
                </div>
                <div class="flex_box content_text--small ">
                  <span>#333333</span>
                  <span>rgb(51, 51, 51)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--75">
                <div class="margin_bottom--ex-small">
                  $color-black--75
                </div>
                <div class="flex_box content_text--small ">
                  <span>#404040</span>
                  <span>rgb(64, 64, 64)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--70">
                <div class="margin_bottom--ex-small">
                  $color-black--70
                </div>
                <div class="flex_box content_text--small ">
                  <span>#4C4C4C</span>
                  <span>rgb(76, 76, 76)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--60">
                <div class="margin_bottom--ex-small">
                  $color-black--60
                </div>
                <div class="flex_box content_text--small ">
                  <span>#666666</span>
                  <span>rgb(102, 102, 102)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--50">
                <div class="margin_bottom--ex-small">
                  $color-black--50
                </div>
                <div class="flex_box content_text--small ">
                  <span>#7F7F7F</span>
                  <span>rgb(127, 127, 127)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--40">
                <div class="margin_bottom--ex-small">
                  $color-black--40
                </div>
                <div class="flex_box content_text--small ">
                  <span>#999999</span>
                  <span>rgb(153, 153, 153)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--30">
                <div class="margin_bottom--ex-small">
                  $color-black--30
                </div>
                <div class="flex_box content_text--small ">
                  <span>#B2B2B2</span>
                  <span>rgb(178, 178, 178)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--20">
                <div class="margin_bottom--ex-small">
                  $color-black--20
                </div>
                <div class="flex_box content_text--small ">
                  <span>#CCCCCC</span>
                  <span>rgb(204, 204, 204)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--10">
                <div class="margin_bottom--ex-small">
                  $color-black--10
                </div>
                <div class="flex_box content_text--small ">
                  <span>#E5E5E5</span>
                  <span>rgb(229, 229, 229)</span>
                </div>
              </div>
              <div class="column_half color-box color-box--black--5">
                <div class="margin_bottom--ex-small">
                  $color-black--5
                </div>
                <div class="flex_box content_text--small ">
                  <span>#F2F2F2</span>
                  <span>rgb(242, 242, 242)</span>
                </div>
              </div>
              <div class="column_full margin-top_ex-small color-box color-box--white">
                <div class="margin_bottom--medium heading--h2 ">
                  $color-white
                </div>
                <div class="flex_box">
                  <span>#FFFFFF</span>
                  <span>rgb(255, 255, 255)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="base_guideline">
            <h2 class="heading--h4 decoration_line_for_section_title">Propose&nbsp;Colors&nbsp;style</h2>
            <div class="flex_box">
              <!-- 此為展示處，請至scss/abstracts/_variable.scss取用色彩 -->
              <div class="flex_box column_half margin_bottom--ex-small container_color">
                <div class="column_full margin_bottom--ex-small color-box color-box--blue">
                  <div class="margin_bottom--medium heading--h2">
                    $color-blue
                  </div>
                  <div class="flex_box">
                    <span>#24B4D6</span>
                    <span>rgb(36, 180, 214)</span>
                  </div>
                </div>
              </div>
              <div class="flex_box column_half margin_bottom--ex-small container_color">
                <div class="column_full margin_bottom--ex-small color-box color-box--red">
                  <div class="margin_bottom--medium heading--h2">
                    $color-red
                  </div>
                  <div class="flex_box">
                    <span>#E65C5C</span>
                    <span>rgb(230, 92, 92)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr class="separate_line">


      <!-- Spacing guidline：距離規範 -->
      <section>
        <h2 class="heading--h4 decoration_line_for_section_title">Size/&nbsp;margin/&nbsp;padding/&nbsp;style&nbsp;guidline</h2>
        <div class="flex_box column_full spacing">
          <div class="">
            <!-- 此為展示處，請至variable.scss取用距離數值 -->
            <div class="ex-small">size極小（4px）：$ex-small</div>
            <div class="small">size小（8px）：$small</div>
            <div class="medium">size適中（16px）：$medium</div>
            <div class="large">size大（24px）：$large</div>
            <div class="ex-large">size極大（32px）：$ex-large</div>
          </div>
        </div>
      </section>
      <hr class="separate_line">


      <!-- 範例：按鈕 -->
      <section>
        <h2 class="heading--h4 decoration_line_for_section_title">Buttons&nbsp;style&nbsp;guideline</h2>

        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box">
          <button type="button" class="column_full margin_bottom--medium btn_normal">基本樣式btn&nbsp;+&nbsp;btn_normal</button>
        </div>
        <!-- copy end -->

        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box">
          <button type="button" class="column_full margin_bottom--medium btn_primary">主樣式btn&nbsp;+&nbsp;btn_primary</button>
        </div>
        <!-- copy end -->

        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box">
          <button type="button" class="column_full margin_bottom--medium btn_primary--line">主樣式line&nbsp;btn&nbsp;+&nbsp;btn_primary--line</button>
        </div>
        <!-- copy end -->

        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box">
          <button type="button" class="column_full margin_bottom--medium btn_accent">強調樣式btn&nbsp;+&nbsp;btn_accent</button>
        </div>
        <!-- copy end -->

        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box">
          <button type="button" class="column_full margin_bottom--medium btn_accent--line">強調樣式line&nbsp;btn&nbsp;+&nbsp;btn_accent--line</button>
        </div>
        <!-- copy end -->

        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box">
          <button type="button" class="column_full margin_bottom--medium btn_secondary">輔助樣式line&nbsp;btn&nbsp;+&nbsp;btn_secondary</button>
        </div>
        <!-- copy end -->

        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉)（btn 需要cirlce_radius時，可直接在button.scss -> 想要的btn裡加上@extend .cirlce_radius） -->
        <div class="flex_box">
          <button type="button" class="column_full margin_bottom--medium btn_primary circle_radius">主樣式橢圓角btn&nbsp;+&nbsp;btn_primary&nbsp;+&nbsp;circle_radius(大圓角)</button>
        </div>
        <!-- copy end -->

         <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box">
          <button type="button" class="column_full margin_bottom--medium btn_cancel">取消樣式btn&nbsp;+&nbsp;btn_cancel</button>
        </div>
        <!-- copy end -->

        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box">
          <button type="button" disabled="disabled" class="column_full margin_bottom--medium btn_disable">不可選取樣式btn&nbsp;+&nbsp;btn_disable</button>
        </div>
        <!-- copy end -->
        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box margin_bottom--medium">
          <button type="button" class="btn_primary btn_icon"><i class="icon-symbol_add"></i></button>
        </div>
        <!-- copy end -->

        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box margin_bottom--medium">
          <button type="button" class="btn_normal btn_icon"><i class="icon-layer"></i></button>
        </div>
        <!-- copy end -->

        <!-- copy start (flex_box和colimn_full 為排版用，可以視情況改掉) -->
        <div class="flex_box">
          <button type="button" :disabled="disabled" :loading="loading" class="column_full status_btn btn_primary">
            <span>根據狀態變化 btn&nbsp;+&nbsp;status_btn</span>
            <div></div>
          </button>
        </div>
        <!-- copy stend -->

      </section>
      <hr class="separate_line">


      <!-- 範例：input/select -->
      <section>
        <h2 class="heading--h4 decoration_line_for_section_title">Input&nbsp;/&nbsp;Select&nbsp;style</h2>
        <!-- 基本型input -->
        <div class="margin_bottom--ex-small">基本型input</div>
        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box container_input--base">
          <input
          type=""
          class="column_full input--base"
          placeholder="範例：這裡寫提示"
          >
        </div>
        <!-- copy end -->

        <hr class="separate_line_dashed">

        <!-- 基本型textarea -->
        <div class="margin_bottom--ex-small">基本型textarea</div>
        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <div class="flex_box container_input--base">
          <textarea
          class="column_full input--base"
          placeholder="範例：這裡寫提示"
          >
          </textarea>
        </div>
        <!-- copy end -->

        <hr class="separate_line_dashed">

        <!-- 基本型select -->
        <div class="margin_bottom--ex-small">基本型select</div>
        <!-- copy start (flex_box和column_full 為排版用，可以視情況改掉) -->
        <label class="flex_box container_select--base">
          <select class="column_full input--base">
            <option value="0">請選擇...</option>
            <option value="1">option1</option>
            <option value="2">option2</option>
          </select>
          <i class="icon-arrow_down"></i>
        </label>
        <!-- copy end -->
      </section>
      <hr class="separate_line">


      <!-- 範例：Checkbox -->
      <section>
        <h2 class="heading--h4 decoration_line_for_section_title">Checkbox&nbsp;style</h2>

        <div class="margin_bottom--small">基本型Checkbox：checkbox--base</div>
        <!-- copy start -->
        <label
          for="checkbox--base"
          class="container_checkbox--base inline_flex_box"
        >
          <input
            type="checkbox"
            id="checkbox--base"
          >
          <div class="checkbox_base"></div>
          <span>checkbox--base</span>
        </label>
        <!-- copy end -->

        <hr class="separate_line_dashed">

        <div class="margin_bottom--small">有圖案按鈕型Checkbox：checkbox--font_icon</div>
        <!-- copy start -->
        <div class="flex_box">
          <label
            for="checkbox--font_icon"
            class="container_checkbox--font_icon inline_flex_box"
          >
            <input
            type="checkbox"
            id="checkbox--font_icon"
            >
            <div class="flex_box btn btn_normal base_radius checkbox--font_icon ">
              <i class="icon-info"></i>
            </div>
            <span>checkbox--font_icon</span>
          </label>
        </div>
        <!-- copy end -->

        <hr class="separate_line_dashed">

        <div class="margin_bottom--small">on/off按鈕型Checkbox：checkbox--toggle</div>
        <!-- copy start -->
        <label
          for="checkbox--toggle"
          class="container_checkbox--toggle"
        >
          <input
            type="checkbox"
            id="checkbox--toggle"
          >
          <div class="flex_box btn btn_normal btn_toggle ">
            <span>checkbox--toggle</span>
          </div>
        </label>
        <!-- copy end -->

        <hr class="separate_line_dashed">

        <div class="margin_bottom--small">有圖on/off按鈕型Checkbox：checkbox--toggle--img</div>
        <!-- copy start -->
        <label
          for="checkbox--toggle--img"
          class="container_checkbox--toggle--img"
        >
          <input
            type="checkbox"
            id="checkbox--toggle--img"
          >
          <div class="flex_box btn btn_normal btn_toggle ">
            <!-- <img alt="" src="./imgs/logo.png" class=""> -->
            <span>checkbox--toggle--img</span>
          </div>
        </label>
        <!-- copy end -->

      </section>
      <hr class="separate_line">


      <!-- 範例：radio -->
      <section>
        <h2 class="heading--h4 decoration_line_for_section_title">Radio&nbsp;style</h2>

        <div class="margin_bottom--small">基本型Radio：radio--base</div>
        <!-- copy start -->
        <label
          for="radio--base"
          class="container_radio--base inline_flex_box"
        >
          <input
            type="radio"
            id="radio--base"
          >
          <div class="radio_base"></div>
          <span>radio--base</span>
        </label>
        <!-- copy end -->

        <hr class="separate_line_dashed">

        <div class="margin_bottom--small">有圖案按鈕型Radio：radio--font_icon</div>
        <!-- copy start -->
        <div class="flex_box">
          <label
            for="radio--font_icon"
            class="container_radio--font_icon inline_flex_box"
          >
            <input
            type="radio"
            id="radio--font_icon"
            >
            <div class="flex_box btn btn_normal base_radius radio--font_icon">
              <i class="icon-info"></i>
            </div>
            <span>radio--font_icon</span>
          </label>
        </div>
        <!-- copy end -->

        <hr class="separate_line_dashed">

        <div class="margin_bottom--small">on/off按鈕型Radio：radio--toggle</div>
        <!-- copy start -->
        <label
          for="radio--toggle"
          class="container_radio--toggle"
        >
          <input
            type="radio"
            id="radio--toggle"
          >
          <div class="flex_box btn btn_normal btn_toggle ">
            <span>radio--toggle</span>
          </div>
        </label>
        <!-- copy end -->

        <hr class="separate_line_dashed">

        <div class="margin_bottom--small">有圖on/off按鈕型Radio：radio--toggle--img</div>
        <!-- copy start -->
        <label
          for="radio--toggle--img"
          class="container_radio--toggle--img"
        >
          <input
            type="radio"
            id="radio--toggle--img"
          >
          <div class="flex_box btn btn_normal btn_toggle ">
            <!-- <img alt="" src="./imgs/logo.png" class=""> -->
            <span>radio--toggle--img</span>
          </div>
        </label>
        <!-- copy end -->

      </section>
      <hr class="separate_line">


      <!-- 範例：方框/卡片 -->
      <section>
        <h2 class="heading--h4 decoration_line_for_section_title">Card&nbsp;style</h2>
        <!-- copy start -->
        <div class="margin_bottom--large card--line">
          <h3 class="heading--h3">卡片--框線樣式</h3>
          <h3 class="margin_bottom--medium">Card--line</h3>
          <p>
            [颱風動態] 根據最新氣象資料顯示，第11號颱風中心目前在金門西方陸地，持續向西北西移動，對臺灣海峽的 威脅已解除。預計此颱風未來有減弱為熱帶性低氣壓的趨勢。 [注意事項] ＊颱風外圍環流影響，臺灣各地(含蘭嶼、綠島)及澎湖、金門、馬祖沿海地區有較強陣風並有長浪發生，前往海邊活動請注意安全。 ＊臺灣附近各海面風浪仍大，航行及作業船隻請特別注意。 ＊自23日0時至25日11時出現較大雨量如下：臺東縣金針山755毫米，花蓮縣六十石山687毫米，屏東縣西大武山597毫米，高雄市南天池419毫米，宜蘭縣太平山297毫米，南投縣小奇萊275毫米，臺中市審馬陣238毫米。出現較大陣風區域如下：蘭嶼14級，綠島12級，大武、彭佳嶼、東吉島11級，臺東、新屋、鞍部、蘇澳、臺南、金門10級。 ＊此為第11號颱風警報最後一次報告。
          </p>
        </div>
        <!-- copy end -->
        <!-- copy start -->
        <div class="card--float">
          <h3 class="heading--h3">卡片--漂浮樣式</h3>
          <h3 class="margin_bottom--medium">Card--float</h3>
          <p>
            [颱風動態] 根據最新氣象資料顯示，第11號颱風中心目前在金門西方陸地，持續向西北西移動，對臺灣海峽的 威脅已解除。預計此颱風未來有減弱為熱帶性低氣壓的趨勢。 [注意事項] ＊颱風外圍環流影響，臺灣各地(含蘭嶼、綠島)及澎湖、金門、馬祖沿海地區有較強陣風並有長浪發生，前往海邊活動請注意安全。 ＊臺灣附近各海面風浪仍大，航行及作業船隻請特別注意。 ＊自23日0時至25日11時出現較大雨量如下：臺東縣金針山755毫米，花蓮縣六十石山687毫米，屏東縣西大武山597毫米，高雄市南天池419毫米，宜蘭縣太平山297毫米，南投縣小奇萊275毫米，臺中市審馬陣238毫米。出現較大陣風區域如下：蘭嶼14級，綠島12級，大武、彭佳嶼、東吉島11級，臺東、新屋、鞍部、蘇澳、臺南、金門10級。 ＊此為第11號颱風警報最後一次報告。
          </p>
        </div>
        <!-- copy end -->
      </section>

      <hr class="separate_line">


      <!-- 範例：分隔線 -->
      <section>
        <h2 class="heading--h4 decoration_line_for_section_title">Line style</h2>
        <!-- 實線 copy start -->
        <hr class="separate_line">
        <!-- copy end -->

        <!-- 雙實線 copy start -->
        <hr class="separate_line_double">
        <!-- copy end -->

        <!-- 虛線 copy start -->
        <hr class="separate_line_dashed">
        <!-- copy end -->
      </section>
      <hr class="separate_line">

      <!-- 範例：讀取 -->
      <section>
        <h2 class="heading--h4 decoration_line_for_section_title">Loader style</h2>
        <!-- copy start -->
        <div class="loader_enlarge"></div>
        <!-- copy end -->

        <hr class="separate_line_dashed">

        <!-- copy start -->
        <div class="loader_rotate"></div>
        <!-- copy end -->
      </section>

      <!-- 範例：Modal -->
      <section>
        <h2 class="heading--h4 decoration_line_for_section_title">Modal</h2>
        <div class="flex_box margin_bottom--medium">
          <button class="btn_primary" @click="default_modal_visible = true">顯示預設Modal</button>
          <button class="btn_primary" @click="modal_visible = true">顯示自訂Modal(用 vue dev tool)</button>
        </div>
        <!-- copy start -->
        <Modal
          :visible.sync="default_modal_visible"
        >
        </Modal>
        <!-- copy end -->
        <!-- copy start -->
        <Modal
          :visible.sync="modal_visible"
          :title="modal_title"
          :content="modal_content"
          :cancel_text="modal_cancel_text"
          :ok_text="modal_ok_text"
          :cancel_btn_required="modal_cancel_btn_required"
          :loading="modal_loading"
          :disabled="modal_disabled"
          :closable="modal_closable"
          :mask_closable="modal_mask_closable"
          esc_to_close
          enter_to_ok
          @confirm="(ok) => { if (ok) { } }"
        >
        </Modal>
        <!-- copy end -->
      </section>
    </article>
  </main>
</template>

<script>
export default {
  name: 'StyleExample',

  data() {
    return {
      loading: true,
      disabled: false,

      default_modal_visible: false,
      modal_visible: false,
      modal_title: '',
      modal_content: '',
      modal_cancel_text: '',
      modal_ok_text: '',
      modal_cancel_btn_required: false,
      modal_loading: null,
      modal_disabled: false,
      modal_closable: false,
      modal_mask_closable: false
    }
  }
}

</script>

<style lang="scss">
.style_example {
  padding: $ex-large;
  line-height: 1.5;
}

.vi_primary {
  @include container-width(6, 4);
  margin: 0 auto;

  //裝飾線
  .decoration_line_for_section_title::after {
    margin: $medium 0;
  }
}

  section {
    padding: $medium 0;
  }

  .section_header {
		position: relative;
		height: 50vh;
		margin-bottom: $ex-large;
    background-color: $color-white;


    //裝飾線
    .decoration_line_for_section_title::after {
      margin: $medium auto;
    }

    .container_row {
      .logo_container {
        width: 12rem;
        height: 12rem;
        padding: $large;
        background-color: $color-primary;
      }

      .logo {
        width: 100%;
      }
    }

    .container_cloumn {
      .logo {
        width: 100%;
      }
    }
  }

  .section_typography {
    .heading--h2--page_title {
      background-color: $color-primary--70;
    }
  }

  //字型規範區塊


  //色彩規範區塊
  .colors_guidline {
  }

  .container_color {
    align-items: center;
    padding: $medium $small;
  }

  .color-box {
    padding: $small $medium;
    color: $color-white;
    font-weight: 700;

    .flex_box {
      flex-direction: column;
      align-items: flex-end;
      opacity: 0.6;
    }

    &--primary {
      background-color: $color-primary;
      border: .1rem solid $color-primary;

      &--30 {
        background-color: $color-primary--30;
        border: .1rem solid $color-primary--30;
        color: $color-black--60;
      }
      &--70 {
        background-color: $color-primary--70;
        border: .1rem solid $color-primary--70;
      }
      &--heavy {
        background-color: $color-primary--heavy;
        border: .1rem solid $color-primary--heavy;
      }
    }

    &--accent {
      background-color: $color-accent;
      border: .1rem solid $color-accent;

      &--40 {
        background-color: $color-accent--40;
        border: .1rem solid $color-accent--40;
        color: $color-black--60;
      }
      &--70 {
        background-color: $color-accent--70;
        border: .1rem solid $color-accent--70;
      }
      &--heavy {
        background-color: $color-accent--heavy;
        border: .1rem solid $color-accent--heavy;
      }
    }

    &--black {
      background-color: $color-black;
      border: .1rem solid $color-black;

      &--80 {
        background-color: $color-black--80;
        border: .1rem solid $color-black--80;
      }
      &--75 {
        background-color: $color-black--75;
        border: .1rem solid $color-black--75;
      }
      &--70 {
        background-color: $color-black--70;
        border: .1rem solid $color-black--70;
      }
      &--60 {
        background-color: $color-black--60;
        border: .1rem solid $color-black--60;
      }
      &--50 {
        background-color: $color-black--50;
        border: .1rem solid $color-black--50;
      }
      &--40 {
        background-color: $color-black--40;
        border: .1rem solid $color-black--40;
      }
      &--30 {
        background-color: $color-black--30;
        border: .1rem solid $color-black--30;
      }
      &--20 {
        background-color: $color-black--20;
        border: .1rem solid $color-black--20;
        color: $color-black--60;
      }
      &--10 {
        background-color: $color-black--10;
        border: .1rem solid $color-black--10;
        color: $color-black--60;
      }
      &--5 {
        background-color: $color-black--5;
        border: .1rem solid $color-black--5;
        color: $color-black--60;
      }
    }

    &--white {
      background-color: $color-white;
      border: .1rem solid $color-black--5;
      color: $color-black--60;
    }

    &--blue {
      background-color: $color-blue;
      border: .1rem solid $color-blue;
    }
    &--red {
      background-color: $color-red;
      border: .1rem solid $color-red;
    }
  }

  //距離區塊
  .spacing {
    align-items: center;
    text-align: center;

    .ex-small {
      border: .1rem solid $color-black--20;
      padding: $ex-small;
      margin-bottom: $ex-small;
    }
    .small {
      border: .1rem solid $color-black--20;
      padding: $small;
      margin-bottom: $small;
    }
    .medium {
      border: .1rem solid $color-black--20;
      padding: $medium;
      margin-bottom: $medium;
    }
    .large {
      border: .1rem solid $color-black--20;
      padding: $large;
      margin-bottom: $large;
    }
    .ex-large {
      border: .1rem solid $color-black--20;
      padding: $ex-large;
      margin-bottom: $ex-large;
    }
  }
</style>