import Vue from 'vue'
import { AccountRepository } from '@/repositories/user'


const UserVState =  new Vue({
  data() {
    return {
      name: undefined,
      email: undefined,

      employee: null
    }
  },

  methods: {
    verify() {
      return new Promise((resolve, reject) => {
        AccountRepository.verify().then((payload) => {
          if (payload.data.user_info) {
            this.name = payload.data.user_info.name
            this.email = payload.data.user_info.email
          }
          this.employee = payload.data.employee
          resolve()
        }).catch(() => {
          reject()
        })
      })
    }
  },
})

export {
  UserVState
}
